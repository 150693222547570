import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, type InputHTMLAttributes } from 'react'
import { cn } from '~/utils/components.ts'

const inputVariants = cva('w-full cursor-pointer placeholder:text-input rounded-md transition focus:outline-none focus:shadow focus:ring-primary disabled:opacity-70 disabled:cursor-not-allowed shadow', {
	variants: {
		scale: {
			default: 'px-4 h-11',
			xs: 'px-4 py-2 text-sm',
			sm: 'p-3',
			xl: 'p-4',
		},
		error: {
			true: 'ring-2 ring-danger/30 shadow-danger/50',
			false: 'ring-1 ring-muted-900/10 shadow',
		},
	},
	defaultVariants: {
		scale: 'default',
		error: false,
	},
})

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ scale, error, className, ...props }, ref) => {
	const inputClasses = cn(inputVariants({ scale, error }), className)

	return <input ref={ref} className={inputClasses} {...props} />
})

Input.displayName = 'Input'

export default Input
